import { envAppBaseURL } from "src/utils/tenantSettings";

const singlePaymentFunction = id => {
  const formActionUrl = `${envAppBaseURL()}payments`;
  if (id && formActionUrl != "") {
    const form = document.createElement("form");
    form.method = "POST";
    form.action = formActionUrl;
    form.style.display = "none";
    const input = document.createElement("input");
    input.name = "proposal_id";
    input.value = id;
    form.appendChild(input);
    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  }
};

function getRiderDisabledMsg({
  rider,
  selectedFeatureOptions = {},
  allRiders,
}) {
  const { disable = {}, is_mandatory = false, is_rider_default } = rider;

  const selectedFeatureKeys = Object.keys(selectedFeatureOptions)?.map(
    feature => feature?.split("_").slice(2).join(" "),
  );

  const disabledRiderKeys = Object.keys(disable)
    ?.filter(key => disable[key] === true)
    .toString()
    .split("_")
    .slice(3)
    .join("_");

  const disabledBy = allRiders
    ?.filter(
      singleRider =>
        rider?.[disabledRiderKeys]?.includes(singleRider?.alias) ?? "",
    )
    ?.map(singleRider => singleRider?.name)
    .join(", ");

  if (is_mandatory) return "This rider is mandatory";
  if (is_rider_default) return "This rider is included by default";

  const riderDisabledMessage = `This rider is ${disabledRiderKeys
    .split("_")
    .join(" ")} ${disabledBy} ${selectedFeatureKeys ?? ""}`;

  return riderDisabledMessage;
}

function getFeatureDisabledMsg({ feature, feature_options, allRiders }) {
  const disableRiderKeys = allRiders
    ?.filter(
      rider =>
        feature?.not_available_with_rider?.includes(rider.alias) &&
        rider.isSelected === true,
    )
    ?.map(rider => rider.name)
    .join(", ");

  const { disable } = feature;
  const disableFeatureKeys = Object.keys(disable)
    .filter(key => disable?.[key] === true)
    .toString()
    .split("_")
    .slice(3)
    .join("_");

  const disableBy = feature_options
    ?.filter(
      singlefeature =>
        singlefeature?.alias === feature?.[disableFeatureKeys]?.[0] ?? "",
    )
    ?.map(feature => feature.name)
    ?.join(", ");

  const featureDisabledMessage = `This Feature is ${disableFeatureKeys
    ?.split("_")
    .join(" ")} ${disableBy} ${disableRiderKeys ?? ""}`;

  return featureDisabledMessage;
}

export { singlePaymentFunction, getRiderDisabledMsg, getFeatureDisabledMsg };

import { useSelector } from "react-redux";
// CUSTOM HOOKS
import { useCart, usePortJourneyConfig, useTheme } from "src/customHooks";
import useGetRouteMatch from "src/customHooks/useGetRouteMatch";
// UTILS
import { matchCurrentRoute } from "src/utils/productDetailsPageUtils";
// STYLED COMPONENTS
import { RiderPremiumButtonStyled } from "./RiderCard.style";
import Spinner from "src/components/Common/Spinner";
import { toast } from "react-hot-toast";
import {
  useFrontendBoot,
  useGetEnquiry,
} from "src/customHooks/useGetCommonQueries";
import { plantypes } from "src/pages/ProductDetails/components/ReviewCart/components";
import { numToLakh } from "src/utils/string.utility";
import { useEventTracking } from "src/customHooks/useEventTracking";

export default function RiderPremiumButton({
  rider,
  isRiderLoading = false,
  onChange,
  isDisabled,
  riderActionBtnClicked,
}) {
  const { colors } = useTheme();
  const { isSelected, rider_members, is_mandatory } = rider;
  const { seeDetails } = useSelector(state => state.utilities);
  const { currentGroupObject } = useGetEnquiry();
  const { getCartEntry } = useCart();
  const { isPortPlan } = usePortJourneyConfig();
  const { journeyType } = useFrontendBoot();
  const { webEngageTrackEvent } = useEventTracking();
  const {
    isCompareRoute,
    isProductDetailsRoute,
    isProposalRoute,
    isProposalSummaryRoute,
    isThankYouRoute,
  } = useGetRouteMatch();
  const isOnProposalOrLaterRoute = matchCurrentRoute({
    matcher: "gte",
    route: "proposal",
  });

  const defaultSelectedRoute =
    isProposalRoute || isProposalSummaryRoute || isThankYouRoute;

  const disableRiderClick =
    defaultSelectedRoute ||
    (seeDetails.isSeeDetailsModalOpen &&
      (isProductDetailsRoute || isCompareRoute));

  const allMemberChange = isSelected => {
    const finalMembers = rider_members?.map(member => ({
      ...member,
      selected: !isSelected,
    }));
    return finalMembers;
  };

  const planTypeValue = alias => {
    switch (true) {
      case isPortPlan(currentGroupObject.id): {
        return "Port";
      }
      case journeyType === "health": {
        return "Base Health";
      }
      case journeyType === "top_up": {
        return alias === "liberty_general"
          ? "Super Top-Up"
          : process.env.REACT_APP_TOPUP_TITLE || "Top Up";
      }
      default: {
        return "";
      }
    }
  };

  const handleChange = () => {
    if (rider?.is_mandatory) return;
    const cartEntry = getCartEntry(currentGroupObject.id);
    webEngageTrackEvent("Riders Selected In Health", {
      "Rider Name": rider?.name,
      "Policy Type": plantypes[currentGroupObject.plan_type],
      "Plan Type": planTypeValue(cartEntry?.product?.company?.alias),
      "Insurer Name": cartEntry?.product?.company?.name,
      "Plan Name": cartEntry?.product?.name,
      Cover: numToLakh(cartEntry?.sum_insured),
      "Policy Term in Years": cartEntry?.tenure,
      "Total Premimum": cartEntry?.total_premium,
      "Cart URL": window.location.href,
    });
    onChange({
      ...rider,
      isSelected: !isSelected,
      rider_members: allMemberChange(isSelected) || [],
    });
  };

  return (
    <RiderPremiumButtonStyled
      id={rider?.id}
      onClick={
        disableRiderClick
          ? () => {
              if (isOnProposalOrLaterRoute) return;
              toast.error("Close This modal to select/deselect riders", {
                position: "top-center",
                id: "rider-action-blocked",
              });
            }
          : handleChange
      }
      colors={colors}
      data-rider-disabled={
        disableRiderClick ? true : isDisabled || is_mandatory
      }
      data-rider-selected={defaultSelectedRoute ? true : isSelected}
      data-action-btn-click={riderActionBtnClicked}
    >
      {isRiderLoading ? (
        <Spinner />
      ) : (
        <input
          type="checkbox"
          checked={defaultSelectedRoute ? true : isSelected}
          readOnly
        />
      )}
    </RiderPremiumButtonStyled>
  );
}

import { useParams } from "react-router-dom";
// CUSTOM COMPONENTS
import { Riders } from "src/pages/ProductDetails/components/Sections/RiderSection";
import { matchCurrentRoute } from "src/utils/productDetailsPageUtils";

export default function AddOnCoverages({ quote }) {
  let { groupCode } = useParams();

  groupCode = groupCode ? parseInt(groupCode) : quote?.group?.id;

  return (
    <Riders
      groupCode={groupCode}
      quote={quote}
      showRiderActions={matchCurrentRoute({
        matcher: "lte",
        route: "productdetails",
      })}
    />
  );
}

import FloatButton from "../Button/FloatButton";
import { FaShare } from "react-icons/fa";
import useShareModal from "src/components/ShareModule/hooks/useShareModal";
import { SHARE_MODULE_STAGES } from "src/components/ShareModule/shareModule.constant";
import { useResponsiveCondition } from "src/pos/hooks";
import { DEVICES } from "src/styles/responsiveStyle";
import { useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTheme } from "src/customHooks";
import toast from "react-hot-toast";

function ShareModuleFloatButton() {
  const { colors } = useTheme();

  const isProductRoute = useRouteMatch({ path: "/productdetails" });
  const isQuotesRoute = useRouteMatch({ path: "/quotes" });
  const isProposalRoute = useRouteMatch({ path: "/proposal" });
  const isCompareRoute = useRouteMatch({ path: "/compare" });
  const isInputRoute = useRouteMatch({ path: "/input" });
  const isSummaryRoute = useRouteMatch({ path: "/proposal_summary" });
  const { width, breakpoint } = useResponsiveCondition(DEVICES.tabletBIG);
  const { handleOpenShareModule } = useShareModal();
  const compareRiderLoading = useSelector(
    state => state.comparePage.compareRiderLoading,
  );

  const { quotes, selectedSectionView } = useSelector(
    state => state.globalComponent.shareModule,
  );

  //FUNCTION/s
  function handleClick() {
    const shareObject = {
      step: 2,
      isOpen: true,
    };

    if (isProductRoute) {
      shareObject.headingIdentifier = "product details";
      shareObject.stage = SHARE_MODULE_STAGES.product;
    }
    if (isProposalRoute) {
      shareObject.headingIdentifier = "proposal";
      shareObject.stage = SHARE_MODULE_STAGES.proposal;
    }
    if (isCompareRoute) {
      shareObject.headingIdentifier = "comparison";
      shareObject.stage = SHARE_MODULE_STAGES.compare;
      shareObject.quotes = quotes;
      shareObject.selectedSectionView = selectedSectionView;
    }
    if (isSummaryRoute) {
      shareObject.headingIdentifier = "summary";
      shareObject.stage = SHARE_MODULE_STAGES.summary;
    }
    handleOpenShareModule(shareObject);
  }

  if (width >= breakpoint || isQuotesRoute || isInputRoute) return <></>;

  return (
    <FloatButton
      onClick={() => {
        if (isCompareRoute && compareRiderLoading) {
          toast("Please wait while riders are loading.", {
            id: "compare-rider-loading",
            position: "top-center",
            style: {
              background: colors.primary_color,
              color: "#fefefe",
            },
          });
        } else {
          handleClick();
        }
      }}
    >
      <FaShare />
    </FloatButton>
  );
}

export default ShareModuleFloatButton;

import { useParams } from "react-router-dom";
import { Button } from "src/components/Common/Button/ContinueButton";
import ICLogoBase from "src/components/Common/ICLogo";
import useShortlistedPlans from "src/pages/ShortlistedPage/hooks/useShortlistedPlans";
import FHTypography from "src/styles/Typography";
import { VIEW } from "src/styles/responsiveStyle";
import { FLEX_COL, FLEX_ROW, ROUNDED, SHADES } from "src/styles/styleGuide";
import { amount, numToLakh } from "src/utils/string.utility";
import styled from "styled-components";
import useShareModal from "../hooks/useShareModal";
import { FaTrash } from "react-icons/fa";
import { useTheme } from "src/customHooks";

const columnTab = ["Insurer", "Premium", "Cover", "Remove"];

function ShareModuleShortlistStep() {
  const { colors } = useTheme();
  const { groupCode } = useParams();
  const { getPlanByGroup, removePlanToShortlist } = useShortlistedPlans();
  const shortlistedQuotes = getPlanByGroup(groupCode);
  const { handleStepChangeShareModule } = useShareModal();

  return (
    <ShareModuleShortlistStepStyled>
      <div className="innerContainer">
        <section className="shortlistStep_head">
          {columnTab.map(headText => {
            return (
              <FHTypography
                className="head_tab"
                key={headText}
                variant="subheading1"
                weight="md"
              >
                {headText}
              </FHTypography>
            );
          })}
        </section>
        <section className="shortlistStep_list">
          {shortlistedQuotes.map(quote => {
            return (
              <div key={quote.total_premium} className="list_card">
                <section className="list_brand">
                  <ICLogoBase
                    className="brandIcon"
                    alias={quote.company_alias}
                    width={70}
                  />
                  <FHTypography variant="subheading2" weight="semi">
                    {quote.product.name}
                  </FHTypography>
                </section>
                <FHTypography variant="subheading2" weight="md">
                  {amount(quote.total_premium)}
                </FHTypography>
                <FHTypography variant="subheading2" weight="md">
                  {numToLakh(quote.sum_insured)}
                </FHTypography>
                <button
                  onClick={() => {
                    removePlanToShortlist(quote);
                  }}
                  className="delete_plan"
                  disabled={shortlistedQuotes.length <= 1}
                >
                  <FaTrash color={colors.primary_color} />
                </button>
              </div>
            );
          })}
        </section>
      </div>
      <Button
        className="nextStep_button"
        onClick={() => {
          handleStepChangeShareModule({ step: 2 });
        }}
      >
        Next
      </Button>
    </ShareModuleShortlistStepStyled>
  );
}

const ShareModuleShortlistStepStyled = styled.div`
  width: 100%;
  padding: 1rem;

  .innerContainer {
    border: 1px solid ${SHADES.LIGHT_GRAY};
    overflow: hidden;
    border-radius: ${ROUNDED.md};
  }

  .shortlistStep_head {
    ${FLEX_ROW};
    align-items: center;
    justify-content: space-between;
    background-color: ${SHADES.LIGHT_GRAY};
    padding: 0.5rem 1rem;

    .head_tab:first-child {
      width: 40%;
    }
  }

  .shortlistStep_list {
    max-height: 300px;
    padding: 0.5rem 1rem;
    overflow-y: scroll;
    ${FLEX_COL};
    gap: 1rem;
    ${VIEW.md} {
      max-height: 300px;
    }

    .list_brand {
      width: 40%;
      ${FLEX_COL};
      ${VIEW.md} {
        ${FLEX_ROW};
        align-items: center;
      }
      .brandIcon {
        height: 2rem;
        border-radius: ${ROUNDED.md};
      }
    }
    .list_card {
      ${FLEX_ROW};
      align-items: center;
      justify-content: space-between;
      text-align: left;

      .delete_plan {
        font-size: 0.8rem;

        :disabled {
          cursor: not-allowed;
        }

        ${VIEW.md} {
          font-size: 0.9rem;
        }
      }
    }
  }

  .nextStep_button {
    width: 150px;
    margin: 0rem auto;
    margin-top: 1rem;
  }
`;
export default ShareModuleShortlistStep;

import styled from "styled-components";
// DESIGN SYSTEM
import { MAX, VIEW } from "src/styles/responsiveStyle";
import { FLEX_ROW, ROUNDED } from "src/styles/styleGuide";

export const DetailsSectionWrap = styled.section`
  margin: 0 auto;
  margin-top: 1.75rem;
  padding: 0 1rem;
  padding-bottom: 1rem;

  ${VIEW.lg} {
    margin: 0 auto;
    margin-top: 2.5rem;
    padding: 0;
    padding-bottom: 1rem;
  }

  ${MAX.lg} {
    margin-bottom: 80px;
  }
  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 85%;
  }
`;

const SeeDetailsModalBodyWrapperStyled = styled.div``;

const SeeDetailsModalFooterWrapperStyled = styled.div`
  /* height: 80px; */
  width: 100%;
  position: fixed;
  bottom: 0px;
  display: block;
  background: #fff;
  padding: 0.75rem;
  box-shadow: 0 -4px 6px -1px rgb(0 0 0 / 0.1);

  ${VIEW.lg} {
    display: none;
  }
`;

const DesktopDetailsSection = styled.div``;

const MobileDetailsSection = styled.div`
  ${VIEW.md} {
    display: none;
  }
`;

const SeeDetailsModalWrapperStyled = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden scroll;
  background-color: #fff;

  ${VIEW.lg} {
    height: 95vh;
    border-top-left-radius: ${ROUNDED.xxl};
    border-top-right-radius: ${ROUNDED.xxl};
  }
`;

const SeeDetailsTabsContainerStyled = styled.div`
  position: relative;
  display: flex;
`;

const SeeDetailsTabsWrapperStyled = styled.div`
  ${FLEX_ROW}
  background-color: ${props => props.colors.primary_shade};
  position: relative;
  justify-content: start;
  margin: 0 auto;
  width: 100%;
  padding: 0 2.5rem;
  padding-top: 1rem;
  scroll-behavior: smooth;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-snap-align: center;
  gap: 1rem;

  ${VIEW.md} {
    justify-content: center;
    overflow: hidden;
    padding: 0 3rem;
    padding-top: 2rem;
    gap: 0;
  }
  ${MAX.md} {
    /*  removing scrollbar on mobile devices */
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`;

const LeftButton = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  content: "";
  z-index: 10;
  width: 24px;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(47, 47, 47, 0.4) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  ${VIEW.md} {
    display: none;
  }
  svg {
    font-size: 0.7rem;
  }
`;

const RightButton = styled.button`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 24px;
  height: 100%;
  z-index: 10;
  background: linear-gradient(
    -90deg,
    rgba(47, 47, 47, 0.4) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  ${VIEW.md} {
    display: none;
  }
  svg {
    font-size: 0.7rem;
  }
`;

const TabStyled = styled.button`
  --radius: ${ROUNDED.lg};
  ${VIEW.md} {
    --radius: ${ROUNDED.xl};
  }
  position: relative;
  background-color: transparent;
  color: #000;
  user-select: none;
  cursor: pointer;
  flex: 1 0 auto;
  text-align: center;
  padding: 0.55rem 1rem;
  ${VIEW.md} {
    padding: 1rem 0.25rem;
  }
  &[data-tab-active="true"] {
    border-radius: var(--radius);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: #fff;
    color: #000;
    box-shadow: 0 18px 10px -20px rgba(0, 0, 0, 0.45) inset;
    &::before {
      z-index: 9;
      border-bottom-right-radius: var(--radius);
      background-color: ${props => props.colors.primary_shade};
    }
    &::after {
      z-index: 9;
      border-bottom-left-radius: var(--radius);
      background-color: ${props => props.colors.primary_shade};
    }

    .left-corner,
    .right-corner {
      background-color: #fff;
    }

    &::before,
    .left-corner {
      content: "";
      position: absolute;
      left: -25px;
      bottom: 0;
      height: 80%;
      width: 25px;
    }
    &::after,
    .right-corner {
      content: "";
      position: absolute;
      right: -25px;
      bottom: 0;
      height: 80%;
      width: 25px;
    }
  }
`;

const BackdropStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
`;

const SeeDetailsModalContainerStyled = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  z-index: 1000;
  overflow: hidden auto;

  ${VIEW.lg} {
    height: 95vh;
    border-top-left-radius: ${ROUNDED.xxl};
    border-top-right-radius: ${ROUNDED.xxl};
  }
`;

const SeeDetailsModalHeaderWrapperStyled = styled.div`
  position: sticky;
  top: 0;
  z-index: 99;
`;

export {
  DesktopDetailsSection,
  LeftButton,
  MobileDetailsSection,
  SeeDetailsModalWrapperStyled,
  SeeDetailsTabsContainerStyled,
  SeeDetailsTabsWrapperStyled,
  RightButton,
  TabStyled,
  SeeDetailsModalBodyWrapperStyled,
  SeeDetailsModalFooterWrapperStyled,
  BackdropStyled,
  SeeDetailsModalContainerStyled,
  SeeDetailsModalHeaderWrapperStyled,
};

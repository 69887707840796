/* eslint-disable max-lines */
import _, { range } from "lodash";
import { STORED_SSO_USER } from "../pos/utility";
import { amount } from "./string.utility";
import { brokerTenant } from "./tenantSettings";

// pages check
export function onWhichPage() {
  const pagesArray = [
    "input",
    "quotes",
    "shortlisted",
    "productdetails",
    "compare",
    "proposal_summary?",
    "proposal",
    "thankyou",
  ];

  const pageValue = pagesArray.find(value =>
    window.location.href.includes(value),
  );
  if (pageValue?.includes("?")) {
    return pageValue.split("?")[0];
  }
  return pageValue;
}

// allowOnSpecificPages
export function allowOnSpecificPages(pages = []) {
  let check = false;
  pages.forEach(page => {
    if (window.location.pathname.includes(page.toString())) {
      check = true;
    }
  });
  return check;
}

// input page visibility
export function inputEnquiryVisibility(settings, section, isPosJourney) {
  if (isPosJourney) {
    return !settings?.b2b_enquiry_visibility?.includes(section);
  } else {
    return !settings?.b2c_enquiry_visibility?.includes(section);
  }
}

export const formatCurrency = (number, decimals, recursiveCall) => {
  const decimalPoints = decimals || 2;
  const noOfLakhs = number / 100000;
  let displayStr;
  let isPlural;

  // Rounds off digits to decimalPoints decimal places
  function roundOf(integer) {
    return +integer.toLocaleString(undefined, {
      minimumFractionDigits: decimalPoints,
      maximumFractionDigits: decimalPoints,
    });
  }

  if (noOfLakhs >= 1 && noOfLakhs <= 99) {
    const lakhs = roundOf(noOfLakhs);
    isPlural = lakhs > 1 && !recursiveCall;
    displayStr = `${lakhs} Lakh${isPlural ? "s" : ""}`;
  } else if (noOfLakhs >= 100) {
    const crores = roundOf(noOfLakhs / 100);
    const crorePrefix =
      crores >= 100000 ? formatCurrency(crores, decimals, true) : crores;
    isPlural = crores > 1 && !recursiveCall;
    displayStr = `${crorePrefix} Crore${isPlural ? "s" : ""}`;
  } else {
    // displayStr = roundOf(+number);
    displayStr = amount(+number);
  }

  return displayStr;
};

export const numberToDigitWord = (
  number,
  type,
  multiple = 50000,
  roundTo = false,
) => {
  if (number <= 100000) {
    multiple = 1000;
  }
  let rounded = Math.round(number / multiple) * multiple;
  const value = String(rounded);
  if (value === "1000000000") return `₹ 100 Crore`;
  if (value.length > 7) {
    let temp = rounded / Math.pow(10, 7);
    if (roundTo && !Number.isInteger(temp)) temp = temp.toFixed(roundTo);
    return `₹ ${temp} Crore`;
  }
  if (value.length > 5) {
    let temp = rounded / Math.pow(10, 5);
    if (roundTo && !Number.isInteger(temp)) temp = temp.toFixed(roundTo);
    return `${type !== "seeDetails" ? "₹" : ""} ${temp} Lakh`;
  }
  // if (value.length > 3) {
  //   let temp = rounded / Math.pow(10, 3);
  //   if (roundTo && !Number.isInteger(temp)) temp = temp.toFixed(roundTo);
  //   return `₹ ${temp} Thousand`;
  // }
  if (number?.toString().length > 3) return `₹ ${number / 1000} Thousand`;
};

export function getPercentageAmount(
  amount,
  percentage,
  fixed_discount_value,
  { roundOf = true } = {},
) {
  let percentageAmount = amount * (percentage / 100);

  if (fixed_discount_value) percentageAmount = fixed_discount_value;

  if (roundOf) return Math.round(percentageAmount);

  return percentageAmount;
}

export function getDiscountAmount(additionalDiscount, cartEntry) {
  const { health_riders, riders, total_premium: basePlanPremium } = cartEntry;

  let discountedAmount = 0;

  const totalPremium = calculateTotalPremium(cartEntry);

  const {
    applied_on_riders,
    applied_on_total_premium,
    applied_on_discounts,
    percent,
    fixed_discount_value,
  } = additionalDiscount;

  if (applied_on_total_premium) {
    const discountedAmountOnTotalPremium = getPercentageAmount(
      totalPremium,
      percent,
    );

    return discountedAmountOnTotalPremium;
  }

  if (applied_on_riders) {
    const ridersList = health_riders || riders || [];
    const applicableRiders = ridersList.filter(rider =>
      applied_on_riders.includes(rider.alias),
    );
    const ridersTotalPremium = applicableRiders.reduce(
      (sum, rider) => (sum += rider.total_premium),
      0,
    );
    const discountedAmount = getPercentageAmount(
      ridersTotalPremium + basePlanPremium,
      percent,
    );

    return discountedAmount;
  }

  if (fixed_discount_value) {
    discountedAmount = +fixed_discount_value;
  }

  if (
    !applied_on_discounts &&
    !applied_on_riders &&
    !applied_on_total_premium &&
    !fixed_discount_value
  ) {
    discountedAmount = getPercentageAmount(basePlanPremium, percent);
  }

  return discountedAmount;
}

function getTotalDiscount(additionalDiscounts, product) {
  let totalPremium = null;
  let totalPremiumWithoutAddons = null;
  let totalRidersPremium = null;
  let totalAddOnsPremium = null;
  let totalDiscount = 0;

  if (product) {
    const {
      total_premium,
      health_riders,
      addons,
      discounts: discountsApplied,
    } = product;

    totalRidersPremium = health_riders?.reduce(
      (sum, rider) => sum + parseInt(rider?.total_premium),
      0,
    );

    totalAddOnsPremium = addons?.reduce(
      (sum, addon) => sum + parseInt(addon.total_premium || addon.premium),
      0,
    );

    totalPremiumWithoutAddons = parseInt(total_premium) + totalRidersPremium;

    totalPremium = totalPremiumWithoutAddons + totalAddOnsPremium;

    const findAdditionalDiscount = alias =>
      additionalDiscounts.find(discount => discount.alias === alias);

    if (discountsApplied) {
      discountsApplied.forEach(discountAlias => {
        const discount = findAdditionalDiscount(discountAlias);
        if (!discount) return;

        if (!discount?.applied_on_riders && !discount?.applied_on_discounts) {
          const discountAmount = getPercentageAmount(
            totalPremium,
            discount?.percent,
            discount?.fixed_discount_value,
          );

          totalPremium -= discountAmount;

          totalDiscount += discountAmount;
          return;
        }

        if (
          discount.applied_on_discounts &&
          discount.applied_on_discounts.some(applied_on_discount =>
            discountsApplied.includes(applied_on_discount),
          )
        ) {
          let discountAmount = 0;
          let thisTotalPremium = product.total_premium;

          discount.applied_on_discounts.forEach(applied_on_discount => {
            const appliedOnDiscount =
              findAdditionalDiscount(applied_on_discount);

            if (appliedOnDiscount.applied_on_riders) {
              const thisDiscountAmount = Math.round(
                (product.total_premium +
                  (product.health_riders
                    ? product.health_riders
                        .filter(rider =>
                          appliedOnDiscount.applied_on_riders.includes(
                            rider.alias,
                          ),
                        )
                        .reduce((sum, rider) => {
                          thisTotalPremium += rider.total_premium;
                          return sum + rider.total_premium;
                        }, 0)
                    : 0)) *
                  (appliedOnDiscount.percent / 100),
              );
              discountAmount += thisDiscountAmount;
            }
          });

          totalPremium -= getPercentageAmount(
            thisTotalPremium - discountAmount,
            discount.percent,
          );
          totalDiscount += getPercentageAmount(
            thisTotalPremium - discountAmount,
            discount.percent,
          );
          return;
        }

        if (discount.applied_on_riders) {
          const discountAmount = Math.round(
            (product.total_premium +
              (product.health_riders
                ? product.health_riders
                    .filter(rider =>
                      discount.applied_on_riders.includes(rider.alias),
                    )
                    .reduce((sum, rider) => sum + rider.total_premium, 0)
                : 0)) *
              (discount.percent / 100),
          );
          totalPremium -= discountAmount;
          totalDiscount += discountAmount;
        }
      });
    }
  }
  return totalDiscount;
}

export function calculateTotalPremium(
  cartEntry,
  { additionalDiscounts = [] } = {},
  journeyType = "health",
  subJourneyType = "new",
  selectedRiders = [],
) {
  const {
    total_premium: basePlanPremium = 0,
    health_riders = [],
    top_up_riders = [],
  } = cartEntry;

  const totalPremium = items =>
    items.reduce((totalPremium, item) => totalPremium + item?.total_premium, 0);

  let ridersPremium = totalPremium(
    journeyType === "health"
      ? subJourneyType === "renewal" && !health_riders.length
        ? selectedRiders
        : health_riders
      : top_up_riders,
  );

  const total_premium = basePlanPremium;

  //? different function is used to calculate discountedAmount
  const discountedAmount = getTotalDiscount(additionalDiscounts, cartEntry);

  let totalPremiumAfterDiscount = total_premium - discountedAmount;

  const addOnsTotalPremium = getAddOnsTotalPremium(cartEntry.addons);

  // if (cartEntry?.product?.company?.alias === "royal_sundaram") {

  totalPremiumAfterDiscount += ridersPremium + addOnsTotalPremium;

  return totalPremiumAfterDiscount;
}

function getMonthsForYearFloor(years) {
  return Math.floor(years * 12);
}

export function getAgeList({ min_child_age_in_days, min_age, max_age }) {
  const minAge = parseFloat(min_age);
  const maxAge = parseFloat(max_age);
  if (minAge < 1) {
    const startMonth = getMonthsForYearFloor(minAge);
    if (maxAge < 1) {
      const endMonth = getMonthsForYearFloor(maxAge);
      return range(startMonth, endMonth + 1).map(month => ({
        code: monthsPercentage(month),
        display_name: month + " Months",
      }));
    }

    if (min_child_age_in_days) {
      if (min_child_age_in_days < 90) {
        return [min_child_age_in_days]
          .map(days => [
            {
              code: 0,
              display_name: "Below 3 months",
              age_in_days: days,
            },
          ])
          .flat()
          .concat(
            range(startMonth, 12).map(month => ({
              code: monthsPercentage(month),
              display_name: month + " Months",
              age_in_days: 0,
            })),
          )
          .concat(
            range(1, maxAge + 1).map(year => ({
              code: year,
              display_name: year + " Years",
              age_in_days: 0,
            })),
          );
      } else if (min_child_age_in_days >= 90 && min_child_age_in_days < 365) {
        const daysToMonth = Math.floor(min_child_age_in_days / 30);

        return range(daysToMonth, 12)
          .map(month => ({
            code: monthsPercentage(month),
            display_name: month + " Months",
            age_in_days: 0,
          }))
          .concat(
            range(1, maxAge + 1).map(year => ({
              code: year,
              display_name: year + " Years",
              age_in_days: 0,
            })),
          );
      } else {
        return range(1, maxAge + 1).map(year => ({
          code: year,
          display_name: year + " Years",
          age_in_days: 0,
        }));
      }
    }
    // This is a temp fix so Remov this else block once min_child_age_in_days has a value in api
    else {
      return range(startMonth, 12)
        .map(month => ({
          code: monthsPercentage(month),
          display_name: month + " Months",
        }))
        .concat(
          range(1, maxAge + 1).map(year => ({
            code: year,
            display_name: year + " Years",
          })),
        );
    }
  }

  return range(minAge, maxAge + 1).map(year => ({
    code: year,
    display_name: year + " Years",
  }));
}

export function getMonthsForYear(years) {
  return Math.round(years * 12);
}

function monthsPercentage(months) {
  return Number(months / 10).toFixed(1);
}

export function getRiderCartData(rider) {
  return {
    ...rider,
    id: rider.id,
  };
}

export function getPlanFeatures(features, sum_insured) {
  let featureList = [];
  let innerData = {};
  features?.forEach((item, index) => {
    featureList.push({
      id: index + 1,
      title: item.name,
      description: item.description,
      displayType: item.display_type[0],
    });
    item?.sum_insureds[sum_insured]?.features?.forEach(innerItem => {
      innerData[item.name] = [
        ...(innerData[item.name] ? innerData[item.name] : []),
        {
          header: innerItem.title,
          short_description: innerItem.short_description,
          description: innerItem.description,
          value: innerItem.feature_value,
          icon: innerItem.other_icon,
          is_visible: innerItem.is_visible,
          code: innerItem.code,
        },
      ];
    });
  });

  return { featureList, innerData };
}

export function getReactSelectOption({ display_name, code }) {
  return { label: display_name, value: code };
}

export const getCustomIcOptions = ({
  display_name,
  code,
  dobRequire,
  expiryDateRequire,
  policyNumberRegex,
}) => {
  return {
    label: display_name,
    value: code,
    dobRequire,
    expiryDateRequire,
    policyNumberRegex,
  };
};

export function mergeQuotes(quotes = [], { sortBy = "relevance" } = {}) {
  const mergedQuotes = {};

  for (let quote of quotes) {
    const {
      product: { name },
    } = quote;

    if (mergedQuotes[name]) {
      mergedQuotes[name] = [...mergedQuotes[name], quote];
      continue;
    }
    mergedQuotes[name] = [quote];
  }

  let sortedMergeQuotes = Object.values(mergedQuotes);

  if (sortBy === "premium_low_to_high") {
    sortedMergeQuotes = Object.values(mergedQuotes).sort();
    sortedMergeQuotes = sortedMergeQuotes.map(quotes =>
      quotes.sort((quoteA, quoteB) => {
        return quoteA?.total_premium > quoteB?.total_premium ? 1 : -1;
      }),
    );

    sortedMergeQuotes = sortedMergeQuotes.sort((quotesA, quotesB) => {
      return quotesA[0]?.total_premium > quotesB[0]?.total_premium ? 1 : -1;
    });
  } else if (sortBy === "premium_high_to_low") {
    sortedMergeQuotes = Object.values(mergedQuotes).sort();
    sortedMergeQuotes = sortedMergeQuotes.map(quotes =>
      quotes.sort((quoteA, quoteB) => {
        return quoteA?.total_premium < quoteB?.total_premium ? 1 : -1;
      }),
    );

    sortedMergeQuotes = sortedMergeQuotes.sort((quotesA, quotesB) => {
      return quotesA[0]?.total_premium < quotesB[0]?.total_premium ? 1 : -1;
    });
  }

  return sortedMergeQuotes;
}

export function capitalize(sentence = "") {
  return sentence.split(" ").map(_.capitalize).join(" ");
}

export function figureToWords(amount) {
  const countInLakhs = parseInt(amount) / 100000;

  if (countInLakhs < 1) {
    return parseInt(amount);
  }

  const countInCrores = countInLakhs / 100;
  if (countInLakhs < 100) {
    return countInLakhs > 1 ? `${countInLakhs} Lakhs` : `${countInLakhs} Lakh`;
  } else {
    return countInCrores > 1
      ? `${countInCrores} Crores`
      : `${countInCrores} Crore`;
  }
}

export function matchQuotes(
  quote1,
  quote2,
  { sum_insured = true, deductible = true } = {},
) {
  return _.every([
    quote1.product.id === quote2.product.id,
    sum_insured
      ? parseInt(quote1.sum_insured) === parseInt(quote2.sum_insured)
      : true,
    deductible ? quote1.deductible === quote2.deductible : true,
  ]);
}

export function matchGroupCodes(groupCode1, groupCode2) {
  return parseInt(groupCode1) === parseInt(groupCode2);
}

export function tenureInWords(tenure) {
  return `${tenure} ${tenure > 1 ? "Years" : "Year"}`;
}

export function getSumInsureds(quotes = []) {
  return quotes.map(quote => quote.sum_insured);
}

export function getFeature(quote, featureCode) {
  return quote?.features?.find(feature => feature.code === featureCode);
}

export function getFeatureForQuotes(quotes, featureCode) {
  return quotes.map(quote => {
    return getFeature(quote, featureCode);
  });
}

export function getTotalPremium(cartEntries = []) {
  let totalPremium = 0;

  for (let cartEntry of cartEntries) {
    totalPremium += +cartEntry?.total_premium;
  }

  return totalPremium;
}

export function getAddOnSendData(addOn) {
  const addOnCartItem = {
    deductible: addOn.deductible,
    members: addOn.members,
    premium: addOn.premium,
    product_id: addOn.product.id,
    sum_insured: addOn.sum_insured,
    tax_amount: addOn.tax_amount,
    tenure: addOn.tenure,
    total_premium: addOn.total_premium,
  };
  return addOnCartItem;
}

export function getInsuranceType(quote) {
  return quote.product.insurance_type.alias;
}

export function isTopUpQuote(quote) {
  const insurance_type = getInsuranceType(quote);

  return insurance_type === "top_up";
}

export function getAddOnsTotalPremium(addOns = []) {
  return addOns.reduce((sum, addOn) => (sum += addOn.total_premium), 0);
}

export function getQuoteKey(quote) {
  // const { product, sum_insured } = quote;
  return `${quote?.product.id}+${quote?.sum_insured}`;
}

export const premiumWithAddons = (netPremium, addons = []) => {
  if (addons.length) {
    return (
      netPremium +
      addons
        .map(singleAddon => singleAddon.total_premium)
        .reduce((acc, amount) => (acc += amount))
    );
  }
  return netPremium;
};

//? year to month
export function months2years(months) {
  var dur1 = parseInt(months) / 12;
  return dur1.toFixed(2);
}

// Getting correct name for broker
export function getBrokerName(brokerName = "") {
  switch (brokerName.toLowerCase()) {
    case "nammacover":
      return "Sriyah Insurance Brokers Pvt. Ltd";
    case "bajaj":
      return "Bajaj Capital Insurance Broking Limited";
    default:
      return brokerName;
  }
}

// disclaimer text
export function renderDisclaimer({ tenantName, settings }) {
  if (localStorage.SSO_user) {
    if (
      settings?.disclaimer_pos &&
      settings?.disclaimer_pos !== "" &&
      settings?.disclaimer_pos !== "<p><br></p>"
    ) {
      return settings.disclaimer_pos;
    } else {
      return `By clicking on Get Started, I hereby authorise ${getBrokerName(
        tenantName,
      )}. and all of
        its affiliates, subsidiaries, group companies and related parties to
        access the details such as my name, address, telephone number, e-mail
        address, birth date and / or anniversary date shared by me, and contact
        me to provide information on the various products and services offered.
        I understand that this consent will override my NDNC registration, if
        any. I also understand that at any point of time, I wish to stop
        receiving such communications from ${getBrokerName(
          tenantName,
        )}, I can withdraw such
        consent anytime on (to provide a contact number or email id or both)`;
    }
  } else {
    if (
      settings?.disclaimer &&
      settings?.disclaimer !== "" &&
      settings?.disclaimer !== "<p><br></p>"
    ) {
      return settings.disclaimer;
    } else {
      return `By clicking on Get Started, I hereby authorise ${getBrokerName(
        tenantName,
      )}. and all of
        its affiliates, subsidiaries, group companies and related parties to
        access the details such as my name, address, telephone number, e-mail
        address, birth date and / or anniversary date shared by me, and contact
        me to provide information on the various products and services offered.
        I understand that this consent will override my NDNC registration, if
        any. I also understand that at any point of time, I wish to stop
        receiving such communications from ${getBrokerName(
          tenantName,
        )}, I can withdraw such
        consent anytime on (to provide a contact number or email id or both)`;
    }
  }
}

// otp validation
export const sellerTypeOtpOptions = settings => {
  const posDetails =
    localStorage.Pos_details && JSON.parse(localStorage.Pos_details);
  if (brokerTenant("pinc")) {
    return { otp: "0", dont_allow_payment: "0" };
  }

  if (brokerTenant("renew_buy")) {
    return STORED_SSO_USER()
      ? settings?.otp_rules?.pos
      : settings?.otp_rules?.customer;
  }

  switch (posDetails?.seller_type) {
    case "P":
      return settings?.otp_rules?.pos;
    case "E":
      return settings?.otp_rules?.employee;
    case "Partner":
      return settings?.otp_rules?.partner;
    default:
      return settings?.otp_rules?.customer;
  }
};

// export const isSSOJourney = () => {
//   if (sessionStorage.SSO_user) return true;

//   if (localStorage.Pos_details) {
//     const posDetails = JSON.parse(localStorage.Pos_details);
//     if (posDetails.seller_type) {
//       return posDetails.seller_type === "P"
//         ? localStorage.SSO_user
//           ? true
//           : false
//         : false;
//     } else {
//       return localStorage.SSO_user ? true : false;
//     }
//   } else {
//     return localStorage.SSO_user ? true : false;
//   }
// };

export const isRelianceInfinityPlan = cartEntry =>
  cartEntry?.product?.name === "Health Infinity Individual";

export const featureOptionsValidValue = featureOptions => {
  if (typeof featureOptions === "string") {
    return JSON.parse(featureOptions);
  }
  return featureOptions;
};

export const getPolicyPremium = (policiesData = []) => {
  if (policiesData?.length) {
    return policiesData
      ?.map(singlePolicy => +singlePolicy?.total_premium)
      ?.reduce((acc = 0, curr) => (acc += curr));
  }
  return 0;
};

export const getTotalPremiumWithDiscount = ({
  netPremiumWithoutDiscount,
  totalDiscountAmount,
}) => {
  return +netPremiumWithoutDiscount - +totalDiscountAmount;
};

export const regexStringToRegex = (regexString = "/^[S]*") => {
  return new RegExp(regexString);
};

export const dateObjectToLocaleString = (dateObject = new Date()) => {
  let date = dateObject?.getDate();
  let month = dateObject?.getMonth() + 1;
  const year = dateObject?.getFullYear();

  if (date < 10) {
    date = "0" + date;
  }
  if (month < 10) {
    month = "0" + month;
  }

  return `${date}/${month}/${year}`;
};

export function number2text(value) {
  var fraction = Math.round(frac(value) * 100);
  var f_text = "";

  if (fraction > 0) {
    f_text = "AND " + convert_number(fraction) + " PAISE";
  }

  return "RUPEES " + convert_number(value) + f_text;
}

function frac(f) {
  return f % 1;
}

function convert_number(number) {
  if (number < 0 || number > 999999999) {
    return "NUMBER OUT OF RANGE!";
  }
  var Gn = Math.floor(number / 10000000); /* Crore */
  number -= Gn * 10000000;
  var kn = Math.floor(number / 100000); /* lakhs */
  number -= kn * 100000;
  var Hn = Math.floor(number / 1000); /* thousand */
  number -= Hn * 1000;
  var Dn = Math.floor(number / 100); /* Tens (deca) */
  number = number % 100; /* Ones */
  var tn = Math.floor(number / 10);
  var one = Math.floor(number % 10);
  var res = "";

  if (Gn > 0) {
    res += convert_number(Gn) + " CRORE";
  }
  if (kn > 0) {
    res += (res == "" ? "" : " ") + convert_number(kn) + " LAKHS";
  }
  if (Hn > 0) {
    res += (res == "" ? "" : " ") + convert_number(Hn) + " THOUSAND";
  }

  if (Dn) {
    res += (res == "" ? "" : " ") + convert_number(Dn) + " HUNDRED";
  }

  var ones = Array(
    "",
    "ONE",
    "TWO",
    "THREE",
    "FOUR",
    "FIVE",
    "SIX",
    "SEVEN",
    "EIGHT",
    "NINE",
    "TEN",
    "ELEVEN",
    "TWELVE",
    "THIRTEEN",
    "FOURTEEN",
    "FIFTEEN",
    "SIXTEEN",
    "SEVENTEEN",
    "EIGHTEEN",
    "NINETEEN",
  );
  var tens = Array(
    "",
    "",
    "TWENTY",
    "THIRTY",
    "FOURTY",
    "FIFTY",
    "SIXTY",
    "SEVENTY",
    "EIGHTY",
    "NINETY",
  );

  if (tn > 0 || one > 0) {
    if (!(res == "")) {
      res += " AND ";
    }
    if (tn < 2) {
      res += ones[tn * 10 + one];
    } else {
      res += tens[tn];
      if (one > 0) {
        res += "-" + ones[one];
      }
    }
  }

  if (res == "") {
    res = "zero";
  }
  return res;
}

export const getAge = (birthYear = "2000") => {
  if (birthYear.length > 4) {
    birthYear = birthYear?.split("-")[2];
  }
  const currentYear = new Date().getFullYear();
  return +currentYear - +birthYear;
};

export const validateName = value => {
  const valueLen = value.length;

  // Validation for "firstname ." or "firstname middlename ."
  if (value[valueLen - 1] === ".") {
    return (
      value[valueLen - 2] === " " &&
      (value.split(" ").length === 3 || value.split(" ").length === 2)
    );
  }

  return !value.includes(".");
};

export function checkMobile(mobile) {
  const mobileString = mobile + "";

  const checkStartDigit = ![0, 1, 2, 3, 4, 5].includes(Number(mobileString[0]));

  const checkDigits = [...new Set(mobileString.split(""))].length > 1;

  return checkStartDigit & checkDigits;
}

/* getUserName : props = name, return = string

 - Check is the journey is pos or not
 - if yes then there is a user name of not 
 - if no then returns pos user name else enquiry username
*/
export function getUserName(name) {
  return name?.split(" ")[0];
}

export const networkStatusChecker = ({
  tenant = "fyntune",
  offlineJSX = <></>,
  serverErrorJSX = <></>,
  status = 500,
}) => {
  if (
    !window.navigator.onLine &&
    (status === 500 || status === "FETCH_ERROR") &&
    tenant === "fyntune"
  ) {
    return offlineJSX;
  } else {
    return serverErrorJSX;
  }
};

export const futureDateValidator = (
  currentDateObj,
  forwardInFuture = { days: 20, months: 2, years: 4 },
) => {
  const tempDateObj = _.cloneDeep(currentDateObj);

  tempDateObj.setDate(tempDateObj.getDate() + forwardInFuture?.days);

  tempDateObj.setMonth(tempDateObj.getMonth() + forwardInFuture?.months);

  tempDateObj.setFullYear(tempDateObj.getFullYear() + forwardInFuture?.years);

  return tempDateObj;
};

export const pastDateValidator = (
  currentDateObj,
  goToPast = { days: 20, months: 2, years: 4 },
) => {
  const tempDateObj = _.cloneDeep(currentDateObj);

  tempDateObj.setDate(tempDateObj.getDate() - goToPast?.days);

  tempDateObj.setMonth(tempDateObj.getMonth() - goToPast?.months);

  tempDateObj.setFullYear(tempDateObj.getFullYear() - goToPast?.years);

  return tempDateObj;
};

export function getFile(base64, filename) {
  var arr = base64.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export const getFormattedDate = (
  dateObj = {},
  reverseLogic = false,
  date = "",
) => {
  if (reverseLogic) {
    return new Date(
      date?.split("-")[0],
      date?.split("-")[1] - 1,
      date?.split("-")[2],
    );
  }

  const tempExpiryDate = dateObjectToLocaleString(new Date(dateObj)).split("/");
  return `${tempExpiryDate[2]}-${tempExpiryDate[1]}-${tempExpiryDate[0]}`;
};

export function getRiderSendData(rider) {
  return {
    ...rider,
    id: rider?.rider_id ?? rider.id,
  };
}

export const base64ToImageSrc = ({ base64, imageMimetype = "image/png" }) => {
  return `data:${imageMimetype};base64,${base64}`;
};

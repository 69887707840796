import ReactDOM from "react-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// REACT ICONS
import { BiSolidLeftArrow, BiSolidRightArrow } from "react-icons/bi";
// CUSTOM HOOKS
import useIsPos from "src/pos/useIsPos";
import {
  useGetSingleICQuote,
  useTheme,
  useUpdateEnquiry,
} from "src/customHooks";
import { useFrontendBoot } from "src/customHooks/useGetCommonQueries";
import useSeeDetailsUtility from "src/customHooks/useSeeDetailsUtility";
import useInfoPopup from "src/components/Common/Popups/InfoPopup/useInfoPopup";
import useSeeDetailsTab from "src/pages/SeeDetailsModal/Hooks/useSeeDetailsTab";
// UTILS
import { allowOnSpecificPages } from "src/utils/helper";
import { removeHtmlTagsFromString } from "src/utils/string.utility";
// DESIGN SYSTEM
import FHTypography from "src/styles/Typography";
// STYLED COMPONENTS
import {
  DetailsSectionWrap,
  LeftButton,
  SeeDetailsModalBodyWrapperStyled,
  SeeDetailsModalFooterWrapperStyled,
  SeeDetailsModalWrapperStyled,
  SeeDetailsTabsContainerStyled,
  SeeDetailsTabsWrapperStyled,
  SeeDetailsModalContainerStyled,
  RightButton,
  TabStyled,
  BackdropStyled,
  SeeDetailsModalHeaderWrapperStyled,
} from "./SeeDetailsModal.style";
// CUSTOM COMPONENTS
import {
  AboutCompany,
  AddOnCoverages,
  CashlessHospitals,
  ClaimProcess,
  PlanDetails,
} from "src/pages/SeeDetailsModal/components/Sections";
import MobileSeeDetailsFooter from "./components/MobileSeeDetailsFooter";
import MobileSeeDetailsHeader from "./components/SeeDetailsHeader/MobileSeeDetailsHeader";
import DesktopSeeDetailsHeader from "./components/SeeDetailsHeader/DesktopSeeDetailsHeader";
// CONSTANTS
import { STORED_POS_COVER_MESSAGE } from "src/pos/utility";
import { PRODUCT_DETAILS_TABS } from "./Hooks/useSeeDetailsTab";
import useHandleBodyScroll from "src/customHooks/useHandleBodyScroll";
import usePosSwitch from "../NewQuotePage/Hooks/usePosSwitch";
import { brokerTenant } from "src/utils/tenantSettings";

export default function SeeDetailsModal() {
  const { colors } = useTheme();

  const { handleOpenInfoPopup } = useInfoPopup();
  const { isPosJourney } = useIsPos();
  const {
    handleCloseSeeDetailsModal,
    seeDetailsCurrentQuote: initialQuote,
    seeDetailSection,
    seeDetailsModalOpen,
  } = useSeeDetailsUtility();

  const { journeyType = "health", settings } = useFrontendBoot();

  const { isLoading: isEnquiryLoading } = useUpdateEnquiry();

  // STATE/s
  const [quote, setQuote] = useState(initialQuote);
  const [isSIChanged, setSIChanged] = useState(false);
  const [currDeductible, setCurDeductible] = useState(quote?.deductible);
  const [currSumInsured, setCurSumInsured] = useState(quote?.sum_insured);
  const { ridersLoading } = useSelector(({ productPage }) => productPage);

  // VARIABLE/s
  const pos_message = settings?.pos_nonpos_switch_message || "";

  // API CALL/s
  const { isLoading, isFetching, data } = useGetSingleICQuote(
    {
      insurerToFetch: quote?.product?.company?.alias,
      sum_insured: currSumInsured,
      deductible: currDeductible,
      groupId: quote?.group?.id,
    },
    {
      skip: !allowOnSpecificPages(["/compare", "/quotes", "/shortlisted"]),
    },
  );

  // CUSTOM HOOK/s
  const {
    showLeftButton,
    scrollClick,
    setActiveKey,
    showRightButton,
    activeKey,
    setScrollableDivRef,
  } = useSeeDetailsTab(seeDetailSection);
  const { hideBodyOverflow, unsetBodyOverflow } = useHandleBodyScroll();
  const { handleB2BToB2C } = usePosSwitch();

  // HANDLER/s
  const handleClose = () => handleCloseSeeDetailsModal();
  const sumInsuredChangeHandler = option => {
    if (+option.value > 500000 && isPosJourney && brokerTenant("hero")) {
      handleB2BToB2C({
        onPopupClick: () => {
          setSIChanged(true);
          setCurSumInsured(option.value);
        },
      });
      return;
    }
    setSIChanged(true);
    setCurSumInsured(option.value);
  };
  const deductibleChangeHandler = options => setCurDeductible(options.value);

  // EFFECT/s
  useEffect(() => {
    setQuote(initialQuote);
  }, [initialQuote]);

  useEffect(() => {
    // Disable scrolling when modal is open
    if (seeDetailsModalOpen) {
      hideBodyOverflow();
    } else {
      unsetBodyOverflow();
    }

    return () => {
      unsetBodyOverflow();
    };
  }, [seeDetailsModalOpen]);

  useEffect(() => {
    if (data?.data?.data) {
      const fetchedQuote = data?.data?.data?.find(quote => {
        if (journeyType === "health") {
          return quote?.product?.id === initialQuote?.product?.id;
        }
        if (isSIChanged) {
          setSIChanged(false);
          return (
            quote?.product?.id === initialQuote?.product?.id &&
            quote?.deductible === currDeductible &&
            quote?.sum_insured === currSumInsured
          );
        }
        return (
          quote?.product?.id === initialQuote?.product?.id &&
          quote?.deductible === currDeductible
        );
      });

      // update riders on the quote
      const updatedQuote = {
        ...fetchedQuote,
        [`${journeyType}_riders`]:
          initialQuote?.[`${journeyType}_riders`] || [],
      };
      setQuote(updatedQuote);
    }
    return () => {};
  }, [data?.data?.data]);

  useEffect(() => {
    if (
      currSumInsured > 500000 &&
      !STORED_POS_COVER_MESSAGE() &&
      pos_message &&
      isPosJourney
    ) {
      handleOpenInfoPopup({
        title: "",
        description: removeHtmlTagsFromString(pos_message),
        buttonText: "OK",
        onClick: () => {
          sessionStorage.setItem("pos_cover_message", "true");
        },
      });
    }
    return () => {};
  }, [currSumInsured]);

  return (
    <SeeDetailsPortal>
      <SeeDetailsModalContainerStyled>
        <SeeDetailsModalWrapperStyled>
          <SeeDetailsModalHeaderWrapperStyled>
            <DesktopSeeDetailsHeader
              quote={quote}
              sumInsuredChangeHandler={sumInsuredChangeHandler}
              deductibleChangeHandler={deductibleChangeHandler}
              onClose={handleClose}
              isLoading={
                isLoading || isFetching || isEnquiryLoading || ridersLoading
              }
            />

            <MobileSeeDetailsHeader
              quote={quote}
              sumInsuredChangeHandler={sumInsuredChangeHandler}
              deductibleChangeHandler={deductibleChangeHandler}
              isLoading={
                isLoading || isFetching || isEnquiryLoading || ridersLoading
              }
              handleClose={handleClose}
            />
          </SeeDetailsModalHeaderWrapperStyled>

          <SeeDetailsModalBodyWrapperStyled>
            <SeeDetailsTabsContainerStyled>
              {showLeftButton && (
                <LeftButton onClick={() => scrollClick("left")}>
                  <BiSolidLeftArrow />
                </LeftButton>
              )}
              <SeeDetailsTabsWrapperStyled
                colors={colors}
                id="tab-wrapper"
                ref={ref => setScrollableDivRef(ref)}
              >
                {PRODUCT_DETAILS_TABS.map((tab, index) => (
                  <TabStyled
                    key={index}
                    onClick={() => setActiveKey(tab.key)}
                    data-tab-active={tab.key === activeKey}
                    id={tab.key}
                    colors={colors}
                  >
                    <span className="left-corner" />
                    <FHTypography variant="h6" weight="base">
                      {tab.title}
                    </FHTypography>
                    <span className="right-corner" />
                  </TabStyled>
                ))}
              </SeeDetailsTabsWrapperStyled>
              {showRightButton && (
                <RightButton onClick={() => scrollClick("right")}>
                  <BiSolidRightArrow />
                </RightButton>
              )}
            </SeeDetailsTabsContainerStyled>
            <DetailsSectionWrap>
              {activeKey === "plan-details" && <PlanDetails quote={quote} />}
              {activeKey === "add-on-coverages" && (
                <AddOnCoverages quote={quote} />
              )}
              {activeKey === "cashless-hospitals" && (
                <CashlessHospitals quote={quote} />
              )}
              {activeKey === "claim-process" && <ClaimProcess quote={quote} />}
              {activeKey === "about-company" && <AboutCompany quote={quote} />}
            </DetailsSectionWrap>
          </SeeDetailsModalBodyWrapperStyled>

          <SeeDetailsModalFooterWrapperStyled>
            <MobileSeeDetailsFooter quote={quote} onClose={handleClose} />
          </SeeDetailsModalFooterWrapperStyled>
        </SeeDetailsModalWrapperStyled>
      </SeeDetailsModalContainerStyled>
    </SeeDetailsPortal>
  );
}

function SeeDetailsPortal({ children }) {
  return ReactDOM.createPortal(
    <>
      <BackdropStyled />
      {children}
    </>,
    document.getElementById("modal-root"),
  );
}
